<template>
  <styled-modal
    id="modal-form-profile"
    @on-hidden="$emit('reset-profile')"
    :title="titleModal"
  >
    <div class="pt-0">
      <div class="file-input d-flex justify-content-center mt-3">
        <!-- <upload-image :imageUrl="image_url" :upload="form_data">
          <template v-slot:footer><div></div></template>
        </upload-image> -->
        
        <label
          class="file-input d-flex justify-content-center align-items-center"
          for="file"
          style="color: rgba(217, 217, 217, 1); border-radius: 23.264px"
          :class="{
            'bg-image': !image_url,
          }"
        >
          <input
            id="file"
            ref="file"
            type="file"
            class="file-link"
            accept="image/png, image/gif, image/jpeg"
            @change="onFileChange"
          />
          <typo
            type="body_web"
            class="label-button-upload-company cursor-pointer w-100 d-flex justify-content-center"
            for="file"
            color="var(--primary-white)"
          >
            <image-icon v-if="!image_url" />
            <img
              v-else
              :src="image_url"
              style="max-height: 120px"
              alt="image-user"
              class="image-organization"
            />
          </typo>
        </label>
      </div>
      <b-row class="mt-3">
        <b-col cols="6">
          <typo type="body_web" class="mb-1" color="var(--primary-black)">
            {{ $t("LB_FIRST_NAME") }}
            <span style="color: red">*</span>
          </typo>

          <b-form-input
            v-model="form_data.first_name"
            id="firstname"
            type="text"
            :placeholder="$t('F_FIRST_NAME')"
            required
          >
          </b-form-input>
          <typo
            type="body_web"
            v-if="!is_validate && v$.form_data.first_name.$error"
            class="error-text"
          >
            {{ $t("VA_ENTER_FIRST_NAME") }}
          </typo>
        </b-col>
        <b-col cols="6">
          <typo type="body_web" class="mb-1" color="var(--primary-black)">
            {{ $t("LB_LAST_NAME") }}
            <span style="color: red">*</span>
          </typo>
          <b-form-input
            v-model="form_data.last_name"
            id="lastname"
            type="text"
            :placeholder="$t('F_LAST_NAME')"
            required
          >
          </b-form-input>
          <typo
            type="body_web"
            color="var(--primary-black)"
            v-if="!is_validate && v$.form_data.last_name.$error"
            class="error-text"
          >
            {{ $t("VA_ENTER_LAST_NAME") }}
          </typo>
        </b-col>
        <b-col cols="6">
          <typo type="body_web" class="mb-1" color="var(--primary-black)">
            {{ $t("LB_EMAIL_ADDRESS") }}
          </typo>
          <b-form-input
            v-model="form_data.email"
            id="email"
            type="text"
            :placeholder="$t('F_EMAIL_ADDRESS')"
            disabled
          />
        </b-col>
        <b-col cols="6">
          <typo type="body_web" class="mb-1" color="var(--primary-black)">
            {{ $t("LB_ROLES") }}
          </typo>
          <b-form-input
            v-model="form_data.role"
            id="role"
            type="text"
            :placeholder="$t('LB_ROLES')"
            disabled
          />
        </b-col>
      </b-row>
    </div>
    <template v-slot:footer>
      <div class="d-flex ga justify-content-end mt-3 w-100">
        <styled-button
          type="primary"
          @on-click="onSave"
          :disabled="is_change"
          style="width: 161px"
        >
          {{ $t("BTN_SAVE") }}
        </styled-button>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import { httpClient } from "@/services/axiosInstance.js";
import store from "@/store";
import axios from "axios";
import { apiURL } from "@/configs/serverConfig";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
// import UploadImage from "@/components/Upload/UploadImage.vue";
import StyledModal from "../../Modal/StyledModal.vue";

export default {
  props: ["mainCompany"],
  components: {
    StyledModal,
    // UploadImage,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      form_data: {
        first_name: null,
        last_name: null,
        role: null,
        file_id: null,
        email: null,
        img: null,
      },
      files: null,
      image_url: null,
      is_validate: true,
      is_change: false,
    };
  },
  computed: {
    titleModal() {
      return this.$t("TXT_PERSONAL_INFORMATION");
    },
  },
  validations() {
    return {
      form_data: {
        first_name: { required },
        last_name: { required },
      },
    };
  },
  watch: {
    mainCompany: "setProfile",

    form_data: {
      handler(newVal) {
        // console.log('main-company',this.mainCompany);
        // console.log('form-data',this.form_data);
        // console.log('new-val',newVal);

        if (this.mainCompany && this.mainCompany.user) {
          if (
            newVal.first_name !== this.mainCompany.user.first_name ||
            newVal.last_name !== this.mainCompany.user.last_name ||
            (this.image_url !==
              (this.mainCompany.user.file && this.mainCompany.user.file.file_url) &&
              (this.mainCompany.user.file && this.mainCompany.user.file.file_url) !==
                undefined)
          ) {
            return (this.is_change = false);
          }
          if (
            (this.mainCompany.user.file && this.mainCompany.user.file.file_url) ===
              undefined &&
            this.image_url
          ) {
            return (this.is_change = false);
          } else {
            return (this.is_change = true);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setProfile(data) {
      if (data) {
        this.form_data = {
          first_name: data.user.first_name,
          last_name: data.user.last_name,
          role: data.main_company_role.name,
          // file: data.user.file ? data.user.file : null,
          file_id: data.user.file_id ? data.user.file_id : null,
          email: data.user.email,
        };
        this.image_url = data.user.file ? data.user.file.file_url : null;
      }
    },
    async onSave() {
      let isValidate = await this.v$.$validate();

      if (isValidate) {
        const url = `${apiURL}/user`;
        try {
          const res_profile = await httpClient.put(url, this.form_data);
          if (res_profile) {
            this.$emit("get-member");
            this.$emit("get-user");
            this.$bvModal.hide("modal-form-profile");
            this.$toast.success({
              component: "styled-toast",
              props: {
                type: "success",
                message: this.$t("TXT_SAVE_DONE"),
              },
            });
          }
        } catch (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      } else {
        this.is_validate = false;
      }
    },
    async onFileChange(e) {
      if (e.target.files[0]) {
        this.files = await e.target.files[0];
        this.image_url = URL.createObjectURL(this.files);

        const url = `${apiURL}/file`;
        let formData = new FormData();
        formData.append("file", this.files ? this.files : null);
        const headers = {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${store.state.token}`,
          "file-path": "profile",
        };
        try {
          const res_files = await axios.post(url, formData, {
            headers: headers,
          });
          if (res_files) {
            this.form_data.file_id = res_files.data.id;
          }
        } catch (e) {
          this.$toast.error(e.response.message);
        }
      }
    },
  },
};
</script>
